<template>
    <b-modal 
  id="modal-primary-form_newpromocional"
  hide-footer
  modal-class="modal-primary"
  centered
  size="lg"
  title="Formulario Nuevo Promocional"
  ref="modalCategoryForm" @hidden="editCategoryHidden">
  <b-row>

    <!-- Field: Username -->
    <b-row
    v-for="blog in [editablePromocional]"
    :key="blog.img"
    md="12"
  >
 
    <b-card
      tag="article"
      no-body style="height: 90% !important;"
    >
  <b-card-body>
    <feather-icon
    icon="EditIcon"
    size="25"
            class="text-danger editCategoryButtonStyle" @click="enableCategoryEdit"
  />
  <table>
    <tr>
      <td>
        <b-img v-if="newCategoryImage === ''"
              :src="blog.img"
              class="card-img-top" style="width: 60% !important; margin-left: 20%;"
              @click="popCatImg"
            />
            <b-img v-else
              :src="newCategoryImage"
              :alt="blog.img.slice(5)"
              class="card-img-top" style="width: 60% !important; margin-left: 20%;"
            />
            <input class="d-none" @change="getNewCategoryImg" type="file" ref="refInputEl" />
      </td>
      <td>
        <span :contenteditable="flagEnableCategoryEdit" class="text-body-heading" @blur="startEditCategory('excerpt', ...arguments)">{{blog.excerpt}}</span>
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range'}"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD" style="width: 200px !important;"
        />
      </td>
    </tr>
    <tr>
      <td>
        <span :contenteditable="flagEnableCategoryEdit" class="text-body-heading" @blur="startEditCategory('name', ...arguments)">{{blog.name}}</span><br/>
        <span :contenteditable="flagEnableCategoryEdit" class="blog-content-truncate" @blur="startEditCategory('description', ...arguments)">{{blog.description}}</span>
      </td>
    </tr>
  </table>
</b-card-body>
        
    </b-card>
    
  </b-row>

  <b-row md="12">
    <b-card style="width: 100%;" v-if="flagViewPromocionalConf">
        <b-card-body>
          <b-card-text>
              Categorias Inscritas
            </b-card-text>


          <b-card id="div1" @drop="dropNew(...arguments)" @dragover="allowDrop(...arguments)" style="width: 100% !important;  height: 200px !important; border-style: solid; overflow-y: auto;">
          <!-- dynamic -->
          </b-card>

          <b-card-text>
              Categorias No Inscritas
            </b-card-text>


          <b-card  id="div2" @drop="drop(...arguments)" @dragover="allowDrop(...arguments)" style="width: 100% !important;  height: 200px !important; border-style: solid; overflow-y: auto;">
            <div style="width: 70% !important; height: 70px !important; border-style: solid;" :id="'drag'+indexSer" draggable="true" @dragstart="dragNew(...arguments, service)" v-for="(service, indexSer) in allServicesLlist" v-bind:key="indexSer">
            {{service.name}}
            </div>

          </b-card>
          <b-link
            class="font-weight-bold" @click="flagViewPromocionalConf = !flagViewPromocionalConf"
          >
            Ver Configuracion
          </b-link>
        </b-card-body>
    </b-card>
</b-row>
  </b-row>
  <!-- <b-card-text>
    Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love
    fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder
    cupcake ice cream tootsie roll jelly.
  </b-card-text> -->
</b-modal>

</template>
<script>
import {
BRow, BCol, BCard, BFormInput, BButton, BCardText, BCardTitle, BMedia, BAvatar, VBModal, BModal, BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination,
} from 'bootstrap-vue';
import { kFormatter } from '@core/utils/filter';
import Promocional from '@/lavarclick/ecommerce/Promocional.ts';
import TimmingManager from '@/lavarclick/ecommerce/TimmingManager.ts';
import flatPickr from 'vue-flatpickr-component';
import moment from 'moment';
import ECommerce from '@/lavarclick/ecommerce/ECommerce.ts';

export default {

props: {

    editablePromocional: Promocional,
    
},

components: {

    BRow, BCol, flatPickr, BCard, BFormInput, BButton, BCardText, BCardTitle, BMedia, BAvatar, VBModal, BModal, BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination

},
data() {

    return {
         
        flagEnableCategoryEdit:false,
        flagViewPromocionalConf:true,
        rangePicker: [moment(new Date()).format('YYYY-MM-DD'), new TimmingManager().getPastDateFromNow(15)],
        allServicesLlist: [],
        newCategoryImage: ''
    }
},
mounted() {

  new ECommerce().getCategoryServices(-1).then((activeCatServices) => {

if (activeCatServices.length > 0) {
  this.allServicesLlist = activeCatServices;
} else {
  this.allServicesLlist = [];
}


});

},
methods: {
  popCatImg(eventx) {
        this.$refs.refInputEl[0].click()
console.log('el timp ', this.$refs);

      },
  getNewCategoryImg(event) {

const file = event.target.files[0];
const reader = new FileReader();
reader.onloadend = () => {
  this.newCategoryImage = reader.result;
this.editablePromocional.img = this.newCategoryImage+'';
  console.log('edita ', this.editablePromocional)
  // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
};
reader.readAsDataURL(file); 
  },

  allowDrop(ev) {
    ev.preventDefault();
  },

  drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
  },

  dragNew(ev, service) {
    ev.dataTransfer.setData("text", ev.target.id);
    this.editablePromocional.services.push({
      id_service: service.id_service,
                label: service.name,
                is_new: 0

    });
  },

  drop(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    ev.target.appendChild(document.getElementById(data));
    console.log('muestr ', this.editablePromocional)
  },

  dropNew(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    ev.target.appendChild(document.getElementById(data));
    
  },

  startEditCategory(property, eventx) {
      this.editablePromocional[''+property] = ''+eventx.srcElement.innerHTML;
      console.log('startEditCategory ', this.editablePromocional);

    },


    enableCategoryEdit() {

        this.flagEnableCategoryEdit = true;


    },


    editCategoryHidden() {

      console.log('cerre00');
      this.editablePromocional.start_at = this.rangePicker[0];
      this.editablePromocional.finish_at = this.rangePicker[1];
      this.$emit('createPromocional', this.editablePromocional);

    },

    tagsColor(tag) {
        if (tag === 'Quote') return 'light-info'
        if (tag === 'Gaming') return 'light-danger'
        if (tag === 'Fashion') return 'light-primary'
        if (tag === 'Video') return 'light-warning'
        if (tag === 'Food') return 'light-success'
        return 'light-primary'
    },
}
}

</script>
<style scoped>

</style>