<template>
  <b-modal id="modal-primary-form" hide-footer modal-class="modal-primary" centered size="lg"
    title="Formulario Nueva Categoria" ref="modalCategoryForm" @hidden="editCategoryHidden">
    <b-row v-if="flagEditableCategory">

      <!-- Field: Username -->
      <b-col v-for="blog in [editableCategory]" :key="blog.img" md="5">
        <b-card-text>
          <!-- Categoria <b-button
        @click="createCategory"
      >
        Primary
      </b-button> -->
        </b-card-text>
        <b-card tag="article" no-body style="height: 90% !important;">
          <feather-icon icon="EditIcon" size="25" class="text-danger editCategoryButtonStyle"
            @click="enableCategoryEdit" />

          <b-card-body>
            <input class="d-none" @change="getNewCategoryImg" type="file" ref="refInputEl" />
            <b-card-title style="text-align: center !important; margin-top: -15px !important;">
              <b-img v-if="newCategoryImage === ''" :src="blog.img" class="card-img-top"
                style="width: 60% !important; margin-left: 20%;" @click="popCatImg" />
              <b-img v-else :src="newCategoryImage" :alt="blog.img.slice(5)" class="card-img-top"
                style="width: 60% !important; margin-left: 20%;" />

              <span :contenteditable="flagEnableCategoryEdit" class="text-body-heading"
                @blur="startEditCategory('name', ...arguments)">{{ blog.name }}</span>
            </b-card-title>
            <b-card-text class="blog-content-truncate">
              <span :contenteditable="flagEnableCategoryEdit" class="blog-content-truncate"
                @blur="startEditCategory('description', ...arguments)">{{ blog.description }}</span><br/>
                Tags: 
              <span :contenteditable="flagEnableCategoryEdit" class="blog-content-truncate"
                @blur="startEditTagsCategory('tags', ...arguments)">{{ blog.tags.tags }}</span>
              <v-select :options="[
                { type: 'Hogar' },
                { type: 'Empresarial' },
                { type: 'Hogar Empresarial' },
              ]" @input="startEditCategoryType" label="type" />
            </b-card-text>
            <b-media no-body>
              <b-media-aside vertical-align="center" class="mr-50">
                <b-avatar href="javascript:void(0)" size="24" :src="blog.avatar" />
              </b-media-aside>
              <b-media-body>
                <small class="text-muted mr-50">by</small>
                <small>
                  <b-link class="text-body">{{ blog.userFullName }}</b-link>
                </small>
                <span class="text-muted ml-75 mr-50">|</span>
                <small class="text-muted">{{ blog.blogPosted }}</small><br />
                Estado <feather-icon icon="UsersIcon" size="25" class="text-danger" />
              </b-media-body>
            </b-media>
            <div class="my-1 py-25">

              <b-link v-for="(tag, index) in blog.tags" :key="index">
                <b-badge pill class="mr-75" :variant="tagsColor(tag)">
                  {{ tag }}
                </b-badge>
              </b-link>
            </div>

            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <b-link :to="{ path: `/pages/blog/${blog.id}#blogComment` }">
                <div class="d-flex align-items-center text-body">
                  <feather-icon icon="MessageSquareIcon" class="mr-50" />

                  <span class="font-weight-bold">{{ kFormatter(blog.comment) }} Comments</span>
                </div>
              </b-link>
              <b-link :to="{ name: 'pages-blog-detail', params: {} }" class="font-weight-bold">
                Read More
              </b-link>


            </div>
            <hr />
            <div v-for="(services, indexServices) in computedEditableCategory.services" v-bind:key="indexServices">
              <span @click="indexSelectedCategoryService = indexServices" style="color: red;">{{ services.name }}</span>
              <div v-for="(serviceItem, itemInd) in services.catalog" v-bind:key="itemInd">
                {{ serviceItem.name }}
              </div>

            </div>
          </b-card-body>
        </b-card>

      </b-col>

      <b-col md="7" style="padding-top: 2%;">
        <b-card>
          <b-card-body>

            <b-img v-if="newSubCategoryImage === ''" :src="newService.img" class="card-img-top"
              style="width: 60% !important; margin-left: 20%;" @click="$refs.fileBtn.click()" />
            <b-img v-else :src="newSubCategoryImage" class="card-img-top"
              style="width: 60% !important; margin-left: 20%;" />

            <input class="d-none" @change="getNewSubCategoryImg" type="file" ref="fileBtn" id="anaxaFile" />

            <b-form-group>
              <p style="white-space: pre-line;">Nombre del Nuevo Servicio</p>
              <b-input-group>
                <b-form-input v-model="newService.name" placeholder="Nombre del Nuevo Servicio" />
                <b-input-group-append class="cursor-pointer" is-text>
                  <!-- <feather-icon
              icon="SearchIcon"
            /> -->
                </b-input-group-append>
              </b-input-group>
              <p style="white-space: pre-line;">Descripcion del Nuevo Servicio</p>
              <b-input-group>
                <b-form-input v-model="newService.description" placeholder="Descripcion del Nuevo Servicio" />
                <b-input-group-append class="cursor-pointer" is-text>


                </b-input-group-append>
              </b-input-group>
              <!-- <p style="white-space: pre-line;">tags</p>
              <b-input-group>
                <b-form-input v-model="newService.tags.tags" placeholder="limpieza, limpio" />
                <b-input-group-append class="cursor-pointer" is-text>
                  <feather-icon  icon="SearchIcon" />

                </b-input-group-append>
              </b-input-group> -->
              <p style="white-space: pre-line;">Config</p>
              <b-input-group>
                  <v-select style="width: 70% !important;" @input="addNewService(newService, ...arguments)" :options="[{label: 'X PESO'}, {label:'X CANTIDAD'}]" label="label" />
              </b-input-group>

            </b-form-group>
            <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="createCategory">Crear
              Categoria</b-button>
          </b-card-body>
        </b-card>

        <!-- <b-card>
            <b-card-body>
                <b-form-group >
                    <p style="white-space: pre-line;">{{newServiceItem.name}}</p>
        <b-input-group>
          <b-form-input
             v-model="newServiceItem.name"
            placeholder=""
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
          >
            <feather-icon
              icon="SearchIcon"
            />
          </b-input-group-append>
        </b-input-group>
        <p style="white-space: pre-line;">{{newServiceItem.ean}}</p>
        <b-input-group >
          <b-form-input
            v-model="newServiceItem.ean"
            placeholder=""
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
          >
            <feather-icon
              icon="SearchIcon" @click="addNewServiceItem(newServiceItem, ...arguments)"
            />
          </b-input-group-append>
        </b-input-group>

        </b-form-group>
            </b-card-body>
        </b-card> -->
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import {
  BRow, BCol, BCard, BFormInput, BButton, BCardText, BCardTitle, BMedia, BAvatar, VBModal, BModal, BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination,
} from 'bootstrap-vue';
import { kFormatter } from '@core/utils/filter';
import Category from '@/lavarclick/ecommerce/Category.ts';
import Service from '@/lavarclick/ecommerce/Service.ts';
import ServiceItem from '@/lavarclick/ecommerce/ServiceItem.ts';
import { computed, watch, ref, onMounted } from '@vue/composition-api';
import vSelect from 'vue-select';
export default {

  props: {


  },

  components: {

    vSelect, BRow, BCol, BCard, BFormInput, BButton, BCardText, BCardTitle, BMedia, BAvatar, VBModal, BModal, BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination

  },
  setup() {

    let flagEnableCategoryEdit = true;
    let indexSelectedCategoryService = 0;
    let editableCategory = new Category();
    let newService = new Service();
    let newServiceItem = new ServiceItem();
    editableCategory.services = [];
    let flagEditableCategory = true;
    let flagEditableCategoryServices = true;
    let computedEditableCategory = computed({
      get: () => {
        return editableCategory;
      },
      set: (editable) => {
        editableCategory = editable;
      }
    });
    let newCategoryImage = ref('');
    let newSubCategoryImage = ref('');
    const getNewCategoryImg = (event) => {

      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        newCategoryImage.value = reader.result;
        computedEditableCategory.value.img = newCategoryImage.value + '';
        console.log('edita ', computedEditableCategory)
        // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
      };
      reader.readAsDataURL(file);
    };

    const getNewSubCategoryImg = (event) => {

      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        newSubCategoryImage.value = reader.result;
        newServiceItem.img = newSubCategoryImage.value + '';
        computedEditableCategory.value.services[0].img = newSubCategoryImage.value + '';
        //console.log(newServiceItem.img);
        // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
      };
      reader.readAsDataURL(file);
    };

    onMounted(() => {

      computedEditableCategory.value = new Category();
      flagEnableCategoryEdit = !flagEnableCategoryEdit;
    })

    return {

      flagEditableCategoryServices,
      flagEnableCategoryEdit,
      flagEditableCategory,
      kFormatter,
      indexSelectedCategoryService,
      editableCategory,
      newService,
      newServiceItem,
      computedEditableCategory, getNewCategoryImg, getNewSubCategoryImg, newSubCategoryImage, newCategoryImage
    }
  },
  methods: {

    popCatImg(eventx) {
      this.$refs.refInputEl[0].click()
      console.log('el timp ', this.$refs);

    },

    addNewServiceItem(newServiceItem, eventx) {

      delete newServiceItem.attributes;
      this.computedEditableCategory.services[this.indexSelectedCategoryService].catalog.push(Object.assign({}, newServiceItem));
      // this.newServiceItem = new ServiceItem();

    },

    addNewService(newService, eventx) {

      console.log('camilo', eventx);
      this.newService.avatar = eventx.label;
      this.computedEditableCategory.services.length > 0 ? this.computedEditableCategory.services[0] = Object.assign({}, this.newService) : this.computedEditableCategory.services.push(Object.assign({}, this.newService));
      console.log('motos ', this.computedEditableCategory);
      // this.newService = new Service();

    },

    editCategoryHidden() {

      console.log('cerrando');

    },
    createCategory(eventx) {

      this.$emit('createCategory', this.computedEditableCategory);

    },
    enableCategoryEdit() {

      this.flagEnableCategoryEdit = !this.flagEnableCategoryEdit;

    },
    hideCategoryService() {


    },
    startEditCategory(property, eventx) {
      this.computedEditableCategory['' + property] = '' + eventx.srcElement.innerHTML;
      console.log('startEditCategory ', this.computedEditableCategory);

    },
    startEditTagsCategory(property, eventx) {
      this.computedEditableCategory.tags.tags = '' + eventx.srcElement.innerHTML;
      console.log('startEditCategorytags ', this.computedEditableCategory);

    },

    startEditCategoryType(eventx) {
      this.computedEditableCategory.userFullName = '' + eventx.type;
      console.log('startEditCategorytags ', this.computedEditableCategory);

    },
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
  }
}

</script>
<style scoped></style>