<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title>Estadisticas !</b-card-title>
      <b-card-text class="mr-0 mb-0">
        Reporte General Categoria
      </b-card-text>
    </b-card-header>
    <b-card-body class="">
      <b-row v-for="(item, indexI) in statisticsItems" :v-bind:key="indexI">
        <b-col :class="item.customClass">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <div class="font-weight-bolder mb-0 text-left row">
                <span style="color: #000000 !important; font-size: 16px !important; padding-right: 5px !important;">{{
                  item.title }}</span>
                <b-card-text class="font-small-3 mb-0">
                  {{ item.subtitle }}
                </b-card-text>
              </div>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
  
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'


export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: ['category'],
  data() {
    return {
      statisticsItems: [
        {
          icon: 'PocketIcon',
          color: 'light-primary',
          title: this.category.length,
          subtitle: 'Total Servicios',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-success',
          title: '12',
          subtitle: 'Total Items Servicio',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Servicios Inactivos',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Este Mes !',
          customClass: '',
        },
      ],
      totalServicesItems: 0
    }
  },
  computed: {

    computedTotalServicesItems: {
      get() {
        this.totalServicesItems;
      },
      set(newVal) {

        this.category.forEach((services) => {

          console.log('desicio', services);
        })
        // this.totalServicesItems = newVal;
      }
    }
  },
  mounted() {
    this.computedTotalServicesItems = 0;
    console.log('movi ', this.category, this.computedTotalServicesItems);
  }
}
</script>
  