<template>
  <content-with-sidebar class="blog-wrapper">

    <div class="pricing-free-trial startTrainingHeaderStyle">
      <b-row>
        <b-col xl="12">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-2" style="margin-left: 10px !important;">
              <b-row>
                <b-col xl="12" cols="12">
                  <b-row>
                    <b-col xl="2" class="mt-2">
                      <b-img fluid :src="require('@/assets/images/logo/logo2.png')" class="pricing-trial-img"
                        alt="svg img" style="height: 120px; width: 120px" />

                    </b-col>
                    <b-col xl="7" class="mt-0">
                      <div class="text-primary" style="margin-top: -10px !important;">
                        CATEGORIAS Y SERVICIOS DE <b>LAVARCLICK !</b> <span class="text-secondary">Administra Todas las
                          Categorias y Servicios de la Plataforma</span>
                      </div>
                      <b-form-group class="blog-search">
                        <b-input-group class="input-group-merge">
                          <b-form-input id="search-input" v-model="computedQuerySearch"
                            placeholder="Buscar categoria o servicio ..." />
                          <b-input-group-append class="cursor-pointer" is-text>
                            <feather-icon icon="SearchIcon" />
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col xl="3" style="padding-top: 0px !important; font-size: 0.8em !important;">
                      ó Crea Una <b-img fluid :src="require('@/assets/images/slider/addcat.png')"
                        class="pricing-trial-img" alt="svg img" style="height: 60px; margin-top: 70px !important;"
                        v-b-modal.modal-primary-form />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>


              <!-- <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="search-input"
            v-model="search_query"
            placeholder="Buscar categoria o servicio ..."
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
          >
            <feather-icon
              icon="SearchIcon"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group> -->
            </div>

            <!-- images -->
            <div
              style="height: 10% !important; width: 30% !important; margin-top: 30px !important; margin-right: -70px !important;">

              <b-img fluid :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
                class="pricing-trial-img" alt="svg img" style="height: 160px" />
            </div>

            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- blogs -->
    <!-- <b-row style="margin-top: -70px !important;">
      <b-col cols="2" style="font-size: 15px !important;">
        Filtrar Categorias de Servicios
        </b-col>
        <b-col cols="4">
        <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input
            id="search-input"
            v-model="search_query"
            placeholder="Buscar categoria o servicio ..."
          />
          <b-input-group-append
            class="cursor-pointer"
            is-text
          >
            <feather-icon
              icon="SearchIcon"
            />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      </b-col>
    </b-row> -->
    <b-overlay :show="loading" rounded="sm">

      <b-row style="margin-top: -70px !important;" v-if="computedCategories.length > 0">
        <b-col xl="12" cols="12">
          <b-row class="blog-list-wrapper">
            <b-col v-for="blog in computedCategories.slice(this.rows.pagesQty, this.rows.pagesLength)" :key="blog.img"
              md="3">
              <b-card tag="article" no-body
                style="height: 90% !important; border-style: solid !important; border-color: #6d36ac !important; border-width: 0.1em !important;">
                <b-link v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-primary variant="outline-primary"
                  @click="setEditableCategory(blog)">
                  <b-img :src="blog.img" :alt="blog.img.slice(5)" class="card-img-top"
                    style="width: 40% !important; margin-left: 30% !important; margin-top: 2% !important; cursor: pointer;" />
                </b-link>
                <b-card-body>
                  <b-card-title style="text-align: center !important; margin-top: 0px !important;">
                    {{ blog.name }}
                  </b-card-title>
                  <b-card-text class="blog-content-truncate">
                    {{ blog.description }}
                  </b-card-text>
                  <b-media no-body>
                    <b-media-aside vertical-align="center" class="mr-50">
                      <b-avatar href="javascript:void(0)" size="24" :src="blog.avatar" />
                    </b-media-aside>
                    <b-media-body>
                      <small class="text-muted mr-50">Aplica</small>
                      <small>
                        <b-link class="text-body">{{ blog.userFullName }}</b-link>
                      </small>
                      <span class="text-muted ml-75 mr-50">|</span>
                      <small class="text-muted">{{ blog.blogPosted }}</small>
                      Estado <feather-icon icon="UsersIcon" size="25" class="text-success" />
                    </b-media-body>
                  </b-media>
                  <div class="my-1 py-25" style="text-align: center !important;">
                    <div style="background-color: #6d36ac !important; color: #FFFFFF !important;">Tags de la Categoria
                    </div>
                    <div class=""
                      style="width: 100% !important; height: 50px !important; background-color: lightgray !important;">

                      {{ blog.tags.tags }}
                    </div>
                  </div>

                  <hr>
                  <!-- <div class="d-flex justify-content-between align-items-center">
              <b-link :to="{ path: `/pages/blog/${blog.id}#blogComment`}">
                <div class="d-flex align-items-center text-body">
                  <feather-icon
                    icon="MessageSquareIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ kFormatter(blog.comment) }} Comments</span>
                </div>
              </b-link>
              <b-link
                :to="{ name: 'pages-blog-detail', params: { id: blog.id } }"
                class="font-weight-bold"
              >
                Read More
              </b-link>
            </div> -->
                </b-card-body>
              </b-card>
            </b-col>
            <b-col cols="12">
              <!-- pagination -->
              <div class="my-2" style="margin-top: -20px !important;" v-if="computedCategories.length > 0">
                <b-pagination v-model="computedCurrentPage" align="center" :total-rows="computedCategories.length"
                  :per-page="4" first-number last-number prev-class="prev-item" next-class="next-item">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <!-- <b-col xl="0">
hola
      </b-col> -->
      </b-row>
    </b-overlay>
    <b-row class="pricing-free-trial startTrainingHeaderStyle">
      <b-col xl="12">
        <div class="" v-if="flagLineChart">
          <div class="" style="margin-left: 30px !important;">
            <b-row>
              <!-- <b-col xl="0">
                  <b-img
              fluid
              :src="require('@/assets/images/illustration/33.png')"
              class=""
              alt="svg img" style="height: 30%"
            />
                </b-col> -->
              <b-col xl="8">
                <LineChart :chartjsData="lineChart" />
              </b-col>
              <!-- <b-col xl="4">
                  <LineChart :chartjsData="lineChart" />
                </b-col> -->
            </b-row>


            <!-- <br/><span class="text-secondary">Verifica en que Estado se Encuentra Todos los Entrenamientos</span>  -->

            <!-- <b-form-group class="blog-search">
                <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal.modal-primary
        variant="outline-primary"
      >
        Primary
      </b-button>
      </b-form-group> -->

          </div>


          <!-- images -->
          <!-- <div style="height: 10% !important; width: 60% !important; margin-top: 20px !important;">
            <b-img
              fluid
              :src="require('@/assets/images/illustration/33.png')"
              class="pricing-trial-img"
              alt="svg img" style="height: 170px"
            />
            </div> -->

          <!--/ images -->
        </div>
      </b-col>
    </b-row>

    <!--/ blogs -->

    <!-- sidebar -->
    <div slot="sidebar" class="blog-sidebar py-2 py-lg-0">
      <h5 class="text-primary">
        PROMOCIONES DE <b>LAVARCLICK!</b> <span class="text-secondary">Adaministra Todas las Promociones de la
          Plataforma</span>
      </h5>
      <!-- input search -->
      <b-form-group class="blog-search">
        <b-input-group class="input-group-merge">
          <b-form-input id="search-input" placeholder="Buscar Promocion...." />
          <b-input-group-append class="cursor-pointer" is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <!--/ input search -->

      <!-- recent posts -->
      <h6 class="section-label mb-75"
        style="font-size: 1em !important; color: #000000 !important; font-weight: bold !important; text-align: center !important;">
        Promociones Activas
      </h6>
      <img src="/img/del.png" @click="openPromocionalForm" v-b-modal.modal-primary-form_newpromocional />

      <div class="blog-recent-posts mt-1"
        style="height: 400px !important; border-style: solid; border-color: #000000; border-width: 0.1em; border-radius: 15px; padding-top: 10px !important;overflow-y: auto; overflow-x: none;">

        <b-media v-for="(recentpost, index) in computedPromocionals" v-bind:key="index" no-body
          :class="index ? 'mt-2' : ''" style="width: 92%; padding-left: 2%;">
          <b-media-body style="text-align: center !important; " v-if="recentpost.is_active">
            <div>
              <b-row>
                <b-col>
                  <b-img :id="'promo' + index" :src="recentpost.img" :alt="recentpost.img.slice(6)" width="100" rounded
                    height="70" @click="setPromocionalIndexSelected(index)" v-b-modal.modal-primary-form_promocional
                    style="width: 30%;" />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  {{ recentpost.name }}
                </b-col>
              </b-row>
            </div>
            <h6 class="blog-recent-post-title">
              <!-- <b-link
                :to="{ name: 'pages-blog-detail', params:{ id :recentpost.id } }"
                class="text-body-heading"
              > -->
              {{ recentpost.title }}
              <!-- </b-link> -->
            </h6>
            <span class="text-muted mb-0">
              {{ new Date(recentpost.created_at * 1) }}
            </span>
            <hr />
          </b-media-body>
        </b-media>

      </div>
      <!--/ recent posts -->

      <!-- categories -->
      <!-- <div class="blog-categories mt-3">
        <h6 class="section-label mb-1">
          Categories
        </h6>

        <div
          v-for="category in blogSidebar.categories"
          :key="category.icon"
          class="d-flex justify-content-start align-items-center mb-75"
        >
          <b-link>
            <b-avatar
              rounded
              size="32"
              :variant="tagsColor(category.category)"
              class="mr-75"
            >
              <feather-icon
                :icon="category.icon"
                size="16"
              />
            </b-avatar>
          </b-link>
          <b-link>
            <div class="blog-category-title text-body">
              {{ category.category }}
            </div>
          </b-link>
        </div>

      </div> -->
      <!--/ categories -->
      <h6 class="section-label mb-75"
        style="font-size: 1em !important; color: #000000 !important; font-weight: bold !important; text-align: center !important; padding-top: 4%;">
        Promociones Pausadas
      </h6>
      <div class="blog-recent-posts mt-1"
        style="height: 400px !important; border-style: solid; border-color: #000000; border-width: 0.1em; border-radius: 15px; padding-top: 10px !important;">
        <b-media v-for="(recentpost, index) in computedPromocionalsInactive" :key="recentpost.img" no-body
          :class="index ? 'mt-2' : ''" style="width: 92%; padding-left: 2%;">
          <b-media-body style="text-align: center !important; ">
            <div>
              <b-row>
                <b-col>
                  <b-img :id="'promo' + index" :src="recentpost.img" :alt="recentpost.img.slice(6)" width="100" rounded
                    height="70" @click="setPromocionalIndexSelected(index)" v-b-modal.modal-primary-form_promocional
                    style="width: 30%;" />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  {{ recentpost.name }}
                </b-col>
              </b-row>
            </div>
            <h6 class="blog-recent-post-title">
              <!-- <b-link
                :to="{ name: 'pages-blog-detail', params:{ id :recentpost.id } }"
                class="text-body-heading"
              > -->
              {{ recentpost.title }}
              <!-- </b-link> -->
            </h6>
            <span class="text-muted mb-0">
              {{ new Date(recentpost.created_at * 1) }}
            </span>
            <hr />
          </b-media-body>
        </b-media>

      </div>
    </div>
    <NewCategoryForm @createCategory="createCategory" />
    <div v-if="flagEditablePromocional">
      <promocional-descriptor :editablePromocional="editablePromocional" @updatePromocional="updatePromocional" />
    </div>
    <div v-if="flagNewPromocional">
      <new-promocional-form :editablePromocional="editablePromocional" @createPromocional="createPromocional" />
    </div>
    <b-overlay :show="loadingModal" rounded="sm">

      <b-modal id="modal-primary" hide-footer modal-class="modal-primary" centered size="lg"
        title="Descripcion Categorias LavarClick !" ref="modalCategory" @hidden="editCategoryHidden">
        <b-row v-if="flagEditableCategory">
          <b-col v-for="blog in [computedEditableCategory]" :key="blog.img" md="5">
            <b-card-text>
              <!-- Categoria <b-button
        @click="createCategory"
      >
        Primary
      </b-button> -->
            </b-card-text>
            <b-card tag="article" no-body
              style="height: 95% !important; border-style: solid; border-width: 0.1em; border-color: #6d36ac;">
              <feather-icon icon="EditIcon" size="25" class="text-danger editCategoryButtonStyle"
                @click="enableCategoryEdit" />
              <input class="d-none" @change="updateCategoryImg" type="file" ref="refInputCatUpdate" />
              <b-img v-if="editableCategoryImage === ''" :src="blog.img" class="card-img-top"
                style="width: 60% !important; margin-left: 20%;" @click="popCatImg" />
              <b-img v-else :src="editableCategoryImage" :alt="blog.img.slice(5)" class="card-img-top"
                style="width: 60% !important; margin-left: 20%;" />
              <b-card-body>
                <b-card-title style="text-align: center !important; margin-top: -15px !important;">
                  <b-link :to="{ name: 'pages-blog-detail', params: { id: blog.id } }" class="text-body-heading">
                  </b-link>
                  <span :contenteditable="flagEnableCategoryEdit" class="text-body-heading"
                    @blur="startEditCategory('name', ...arguments)">{{ blog.name }}</span>
                </b-card-title>
                <b-card-text class="blog-content-truncate">
                  <span :contenteditable="flagEnableCategoryEdit" class="blog-content-truncate"
                    @blur="startEditCategory('description', ...arguments)">{{ blog.description }}</span>
                </b-card-text>
                <b-media no-body>
                  <b-media-aside vertical-align="center" class="mr-50">
                    <b-avatar size="24" :src="blog.avatar" />
                  </b-media-aside>
                  <b-media-body>
                    <small class="text-muted mr-50">bys</small>
                    <small>
                      <b-link class="text-body">{{ blog.userFullName }}</b-link>
                    </small>
                    <span class="text-muted ml-75 mr-50">|</span>
                    <small class="text-muted">{{ blog.blogPosted }}</small><br />
                    Estado <feather-icon icon="UsersIcon" size="25" class="text-danger" />
                  </b-media-body>
                </b-media>
                <div class="my-1 py-25" style="text-align: center !important;">
                  <div style="background-color: #6d36ac !important; color: #FFFFFF !important;">Tags de la Categoria</div>
                  <div class=""
                    style="width: 100% !important; height: 50px !important; background-color: lightgray !important;">

                    {{ blog.tags.tags }}
                  </div>
                </div>

                <!-- <hr>
            <div class="d-flex justify-content-between align-items-center">
              <b-link :to="{ path: `/pages/blog/${blog.id}#blogComment`}">
                <div class="d-flex align-items-center text-body">
                  <feather-icon
                    icon="MessageSquareIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ kFormatter(blog.comment) }} Comments</span>
                </div>
              </b-link>
              <b-link
                :to="{ name: 'pages-blog-detail', params: { id: blog.id } }"
                class="font-weight-bold"
              >
                Read Mores
              </b-link>
            </div> -->
                <div style="height: 50% !important;">
                  <CategoryResume :category="computedEditableCategory.services" />
                </div>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="12" md="7">
            <b-card-text v-if="flagEditableCategoryServices">
              <!-- Servicios Incluidos en la Categoria -->
              <b-form-group class="blog-search">
                <b-input-group class="input-group-merge">
                  <b-form-input id="search-input" placeholder="Buscar un Servicio ..." />
                  <b-input-group-append class="cursor-pointer" is-text>
                    <feather-icon icon="SearchIcon" />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-card-text>
            <b-card-text v-else>
              <!-- Servicio {{computedEditableCategory.services[indexSelectedCategoryService].name}} -->
            </b-card-text>
            <div v-if="flagEditableCategoryServices" style="height: 250px !important; overflow-y: auto !important;">
              <table class="serviceTableStyle">
                <tr>
                  <th>COD</th>
                  <th>SERVICIO</th>
                  <th>ESTADO</th>
                  <th>Edit</th>
                </tr>
                <tr v-for="(service, indexSer) in computedEditableCategory.services" v-bind:key="indexSer">
                  <td @click="showCategoryService(service, indexSer)"
                    style="cursor: pointer; padding-left: 2%; font-size: 0.8em;">{{ service.code }}</td>
                  <td @click="showCategoryService(service, indexSer)" style="cursor: pointer;">{{ service.name }}</td>
                  <td @click="showCategoryService(service, indexSer)" style="cursor: pointer;">{{ service.is_active ?
                    'Activo' : 'Inactivo' }}</td>
                  <td> <feather-icon icon="EditIcon" size="25" class="text-danger"
                      @click="enableCategoryServiceEdit(service)" /></td>
                </tr>

              </table>
            </div>
            <div v-else>
              <table class="serviceTableStyle">
                <tr>
                  <td>
                    <b-card tag="article" no-body style="height: 90% !important;">

                      <b-card-body>
                        <b-row>
                          <b-col xl="2">

                            <!-- <b-link
                              :to="{ name: 'pages-blog-detail', params: { id: editableCategory.services[indexSelectedCategoryService].id } }"> -->

                              <b-img :src="computedEditableCategory.services[indexSelectedCategoryService].img + ''"
                                style="width: 120% !important; margin-left: 20%;" />
                            <!-- </b-link> -->
                          </b-col>
                          <b-col xl="9">
                            <span :contenteditable="flagEnableServiceEdit" class="text-body-heading"
                              @blur="startEditService('name', ...arguments)"
                              style="font-family: MavenPro !important; font-size: 1.3em;">{{
                                computedEditableCategory.services[indexSelectedCategoryService].name }}</span>
                                <div class="row" style="padding-left: 5% !important;">
                            <b-card-text class="blog-content-truncate">
                              {{ computedEditableCategory.services[indexSelectedCategoryService].description }}<br/> COBRO {{ computedEditableCategory.services[indexSelectedCategoryService].avatar }}
                            </b-card-text>                            
                            


</div>
                            <div class="">
                              <b-link
                                v-for="(tag, index) in computedEditableCategory.services[indexSelectedCategoryService].tags"
                                :key="index">
                                <b-badge pill :variant="tagsColor(tag)">
                                  {{ tag }} 
                                </b-badge>
                              </b-link>
                            </div>
                            <div>
                              
                            </div>
                          </b-col>
                          <!-- <b-col>
                            <feather-icon icon="EditIcon" size="25" class="text-danger editCategoryButtonStyle"
                              @click="enableServiceEdit" />

                          </b-col> -->
                        </b-row>
                        <b-row
                          style="height: 60% !important; max-height: 60% !important; overflow-y: auto !important; margin-top: 0px !important;">
                          <b-col>
                            <table class="serviceTableStyle">
                              <tr>
                                <th>COD</th>
                                <th>ITEM DEL SERVICIO</th>
                                <th style="width: 20%;">VALOR</th>
                                <th>Edit</th>
                              </tr>
                              <tr
                                v-for="(serviceItem, indexSerItem) in computedEditableCategory.services[indexSelectedCategoryService].catalog"
                                v-bind:key="indexSerItem">
                                <td>{{ serviceItem.code }}</td>
                                <td>{{ serviceItem.name }}</td>
                                <td style="text-align: right; width: 20%;">$ {{ serviceItem.price }}</td>
                                <td> <feather-icon icon="EditIcon" size="25" class="text-danger"
                      @click="enableServiceItemEdit(serviceItem)" /></td>
                              </tr>
                            </table>

                          </b-col>
                        </b-row>

                        <b-row style="padding-top: 5%;">
                          <b-col>
                            <p
                              style="white-space: pre-line; width: 100% !important; color: #FFFFFF !important; background-color: #6d36ac !important;">
                              Formulario Nuevo Item de Servicio</p>
                            <b-form-group>
                              <!-- <p style="white-space: pre-line;">{{newServiceItem.name}}</p> -->
                              <b-input-group>
                                <b-input-group-append class="cursor-pointer" is-text>
                                  Nuevo Item Servicio
                                </b-input-group-append>
                                <b-form-input v-model="newServiceItem.name" placeholder="" />
                              </b-input-group>
                              <!-- <p style="white-space: pre-line;">{{newServiceItem.ean}}</p> -->
                              <!-- OJOOJOJOJO <b-input-group>
                                <b-input-group-append class="cursor-pointer" is-text>
                                  Descripcion Item
                                </b-input-group-append>
                                <b-form-input v-model="newServiceItem.ean" placeholder="Descripcion Nuevo Item..." />
                              </b-input-group> -->

                              <b-input-group>
                                <b-input-group-append class="cursor-pointer" is-text>
                                  Precio
                                </b-input-group-append>
                                <b-form-input v-model="newServiceItem.price" placeholder="" style="width: 20%;" />
                                <b-input-group-append class="cursor-pointer" is-text>
                                  Peso
                                </b-input-group-append>
                                <b-form-input v-model="newServiceItem.weight" placeholder="" />
                                <b-input-group-append class="cursor-pointer" is-text>
                                  Cant
                                </b-input-group-append>
                                <b-form-input v-model="newServiceItem.quantity" placeholder="" />
                              </b-input-group>

                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col xl="4" style="text-align: center !important;">
                            <b-img v-if="newSubCategoryImage === ''" fluid
                              :src="newServiceItem.image" class="pricing-trial-img" alt="svg img"
                              style="height: 120px; width: 120px" @click="popServItemImg" />
                            <b-img v-else fluid :src="newSubCategoryImage" class="pricing-trial-img" alt="svg img"
                              style="height: 120px; width: 120px" />
                            <input @change="getNewSubCategoryImg" type="file" ref="fileBtn" class="d-none" />
                          </b-col>
                          <b-col style="margin-top: 8% !important;" xl="8">
                            <b-button variant="primary" v-if="flagUpdateServicesItem" class="mb-75" block @click="updateServiceItem">
                  Actualizar Item Servicio
                </b-button>
                            <b-button variant="primary" class="mb-75" block @click="createServiceItem" v-else>
                              Crear Item Servicio
                            </b-button>

                          </b-col>
                        </b-row>
                        <b-row style="padding-top: 5%;">
                          <b-col xl="3"></b-col>
                          <b-col xl="9">
                            <div class="d-flex center-content-between align-items-center">
                              <!-- <b-link :to="{ path: `/pages/blog/${computedEditableCategory.services[indexSelectedCategoryService].id}#blogComment`}">
                <div class="d-flex align-items-center text-body">
                  <feather-icon
                    icon="MessageSquareIcon"
                    class="mr-50"
                  />
                  <span class="font-weight-bold">{{ kFormatter(computedEditableCategory.services[indexSelectedCategoryService].comment) }} Comments</span>
                </div>
              </b-link> -->
                              <b-link @click="hideCategoryService()" class="font-weight-bold">
                                [ Regresar a Servicios ]
                              </b-link>
                            </div>
                          </b-col>
                        </b-row>
                      </b-card-body>
                    </b-card>
                  </td>
                </tr>
              </table>
            </div>
            <b-form-group v-if="flagEditableCategoryServices" style="padding-top: 15px !important;">
              <p
                style="white-space: pre-line; width: 100% !important; color: #FFFFFF !important; background-color: #6d36ac !important;">
                Formulario Nuevo Servicio</p>
              <b-input-group>
                <b-input-group-append is-text>
                  Nombre Nuevo Servicio
                </b-input-group-append>

                <b-form-input v-model="newService.name" placeholder="Nombre del Nuevo Servicio" />
              </b-input-group>
              <!-- <p style="white-space: pre-line;">Descripcion del Nuevo Servicio</p> -->
              <b-input-group>
                <b-input-group-append is-text>

                  Descripcion

                </b-input-group-append>
                <b-form-input v-model="newService.description" placeholder="Descripcion del Nuevo Servicio" />
              </b-input-group>
              <b-input-group >
                <b-input-group-append is-text>

                  Imagen

                </b-input-group-append>
                <b-img v-if="newCategoryImage === ''" fluid :src="newService.img" @click="popServImg"
                  class="pricing-trial-img" alt="svg img" style="height: 120px; width: 120px" />
                <b-img v-else fluid :src="newCategoryImage" class="pricing-trial-img" alt="svg img"
                  style="height: 120px; width: 120px" @click="popServImg" />
                <input @change="getNewCategoryImg" type="file" ref="fileBtnUpdateServ" class="d-none" />
                <div>
                Configuracion
                <v-select style="width: 100% !important;" v-model="newService.avatar" :options="['X PESO', 'X CANTIDAD']" label="label" />
              </div>
                <!-- <b-form-input
             v-model="newService.tags.tags"
            placeholder="limpieza, limpio"
          /> -->
              </b-input-group>
            </b-form-group>
            <b-row v-if="flagEditableCategoryServices">
              <b-col xl="4" style="text-align: center !important;">

              </b-col>
              <b-col style="margin-top: 8% !important;" xl="6">
                <b-button variant="primary" v-if="flagUpdateServices" class="mb-75" block @click="updateService">
                  Actualizar Servicio
                </b-button>
                <b-button variant="primary" v-else class="mb-75" block @click="createService">
                  Crear Servicio
                </b-button>

              </b-col>
            </b-row>


          </b-col>
        </b-row>
      </b-modal>
    </b-overlay>
  </content-with-sidebar>
</template>

<script>
import {
  BRow, BCol, BOverlay, BCard, BFormInput, BButton, BCardText, BCardTitle, BMedia, BAvatar, VBModal, BModal, BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination,
} from 'bootstrap-vue';
import BCardCode from '@core/components/b-card-code';
import { kFormatter } from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import ECommerce from '../../ecommerce/ECommerce.ts';
import Ripple from 'vue-ripple-directive'
import { codeThemes } from './code';
import LineChart from '../charts/LineChart.vue';
import Category from '@/lavarclick/ecommerce/Category.ts';
import FilterObjects from '@/lavarclick/ecommerce/FilterObjects.ts';
import NewCategoryForm from './NewCategoryForm.vue';
import PromocionalDescriptor from './PromocionalDescriptor.vue';
import NewPromocionalForm from './NewPromocionalForm.vue';
// import TimmingManager from '../../ecommerce/TimmingManager.ts';
import Service from '@/lavarclick/ecommerce/Service.ts';
import ServiceItem from '@/lavarclick/ecommerce/ServiceItem.ts';
import Attribute from '@/lavarclick/ecommerce/Attribute.ts';
import CategoryResume from './CategoryResume.vue';
import Promocional from '@/lavarclick/ecommerce/Promocional.ts';
import ToastContent from '@/lavarclick/components/utils/ToastContent.vue';
import vSelect from 'vue-select';
Ripple.zIndex = 1;

export default {
  components: {
    BButton,
    BRow,
    BCol, vSelect,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    VBModal, BOverlay,
    BCardCode,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BModal,
    BPagination,
    ContentWithSidebar,
    LineChart,
    NewCategoryForm,
    CategoryResume,
    PromocionalDescriptor,
    NewPromocionalForm

  },
  data() {
    return {
      editableCategoryImage: '',
      newCategoryImage: '',
      newSubCategoryImage: '',
      loading: true,
      loadingModal: false,
      promocional: new Promocional(),
      search_query: '',
      newService: new Service(),
      newServiceItem: new ServiceItem(),
      blogList: [],
      blogSidebar: {},
      currentPage: 1,
      perPage: 1,
      rows: {
        pagesQty: 0,
        pagesLength: 4
      },
      ecommerce: new ECommerce(),
      promocionals: [],
      promocionalsInactive: [],
      rewardsList: [],
      codeThemes,
      editableCategory: {},
      flagEditableCategory: false,
      flagEditablePromocional: false,
      flagNewPromocional: false,
      flagEditableCategoryServices: true,
      flagUpdateServices: false,
      flagUpdateServicesItem: false,
      indexSelectedCategoryService: 0,
      indexSelectedPromocional: 0,
      flagEnableCategoryEdit: false,
      flagEnableServiceEdit: false,
      chartColors: {
        primaryColorShade: '#836AF9',
        yellowColor: '#ffe800',
        successColorShade: '#28dac6',
        warningColorShade: '#ffe802',
        warningLightColor: '#FDAC34',
        infoColorShade: '#299AFF',
        greyColor: '#4F5D70',
        blueColor: '#2c9aff',
        blueLightColor: '#84D0FF',
        greyLightColor: '#EDF1F4',
        tooltipShadow: 'rgba(0, 0, 0, 0.25)',
        lineChartPrimary: '#666ee8',
        lineChartDanger: '#ff4961',
        labelColor: '#6e6b7b',
        grid_line_color: 'rgba(200, 200, 200, 0.2)',
      },
      flagLineChart: false,
      lineChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: 'label',
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: '#FFFFFF',
            titleFontColor: '#000000',
            bodyFontColor: '#000000',
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  stepSize: 100,
                  min: 0,
                  max: 5,
                  fontColor: '#6e6b7b',
                },
                gridLines: {
                  display: true,
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
              },
            ],
          },
          legend: {
            position: 'top',
            align: 'start',
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        },
        data: {
          labels: [], // new TimmingManager().getPastDateListFromNow(15, true), //['07/25', 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140],
          datasets: [
            {
              data: [],
              label: 'Lavanderia',
              borderColor: '#ad362d',
              lineTension: 0.5,
              pointStyle: 'circle',
              backgroundColor: '#ad362d',
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: 'transparent',
              pointHoverBorderColor: '#FFFFFF',
              pointHoverBackgroundColor: '#ad362d',
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: '#544b4a',
            },
            // {
            //   data: [80, 125, 105, 130, 215, 195, 140, 160, 230, 300, 220, 170, 210, 200, 280],
            //   label: 'Asia',
            //   borderColor: chartColors.lineChartPrimary,
            //   lineTension: 0.5,
            //   pointStyle: 'circle',
            //   backgroundColor: chartColors.lineChartPrimary,
            //   fill: false,
            //   pointRadius: 1,
            //   pointHoverRadius: 5,
            //   pointHoverBorderWidth: 5,
            //   pointBorderColor: 'transparent',
            //   pointHoverBorderColor: '#FFFFFF',
            //   pointHoverBackgroundColor: chartColors.lineChartPrimary,
            //   pointShadowOffsetX: 1,
            //   pointShadowOffsetY: 1,
            //   pointShadowBlur: 5,
            //   pointShadowColor: chartColors.tooltipShadow,
            // },
            // {
            //   data: [80, 99, 82, 90, 115, 115, 74, 75, 130, 155, 125, 90, 140, 130, 180],
            //   label: 'Limpieza Hogar',
            //   borderColor: '#f2be6b',
            //   lineTension: 0.5,
            //   pointStyle: 'circle',
            //   backgroundColor: '#f2be6b',
            //   fill: false,
            //   pointRadius: 1,
            //   pointHoverRadius: 5,
            //   pointHoverBorderWidth: 5,
            //   pointBorderColor: 'transparent',
            //   pointHoverBorderColor: '#FFFFFF',
            //   pointHoverBackgroundColor: '#f2be6b',
            //   pointShadowOffsetX: 1,
            //   pointShadowOffsetY: 1,
            //   pointShadowBlur: 5,
            //   pointShadowColor:  '#544b4a',
            // },
          ],
        },
      },
      bufferCategoriesList: [],
      //   {
      //     id: 1,
      //     img: require('@/assets/images/slider/02.jpg'),
      //     title: 'Servicios de Laundry',
      //     avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
      //     userFullName: 'Validez',
      //     blogPosted: 'Válido desde 2022-07-15 hasta 2022-12-31',
      //     tags: ['Alimentacion', 'Pasteleria', 'Cumpleaños'],
      //     excerpt: 'Torta Especial 100 CarboPuntos, Torta Natural 75 CarboPuntos',
      //     comment: 76,
      //   },
      //   {
      //     id: 2,
      //     img: require('@/assets/images/slider/06.jpg'),
      //     title: 'Limpieza Industrial',
      //     avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
      //     userFullName: 'Validez',
      //     blogPosted: 'Válido desde 2022-07-15 hasta 2022-12-31',
      //     tags: ['S-01 Laundry x Prendas', 'S-02 Laundry x Peso'],
      //     excerpt: 'Recibe este Combo con 150 CarboPuntos',
      //     comment: 2100,
      //   },
      //   {
      //     id: 3,
      //     img: require('@/assets/images/slider/04.jpg'),
      //     title: 'Limpieza del Hogar',
      //     avatar: require('@/assets/images/portrait/small/avatar-s-3.jpg'),
      //     userFullName: 'Claudia Neal',
      //     blogPosted: 'Jan 10, 2020',
      //     tags: ['Familia', 'Dia en Familia', 'Padres'],
      //     excerpt: 'Adquiere un Pase Dorado por 165 CarboPuntos',
      //     comment: 243,
      //   },
      //   {
      //     id: 4,
      //     img: require('@/assets/images/slider/03.jpg'),
      //     title: 'Withhold a Compelling Piece of Information',
      //     avatar: require('@/assets/images/portrait/small/avatar-s-14.jpg'),
      //     userFullName: 'Fred Boone',
      //     blogPosted: 'Jan 10, 2020',
      //     tags: ['Video'],
      //     excerpt: 'Croissant apple pie lollipop gingerbread. Cookie jujubes chocolate cake icing cheesecake.',
      //     comment: 10,
      //   },
      //   {
      //     id: 5,
      //     img: require('@/assets/images/slider/09.jpg'),
      //     title: 'Unadvertised Bonus Opening: Share a Quote',
      //     avatar: require('@/assets/images/portrait/small/avatar-s-13.jpg'),
      //     userFullName: 'Billy French',
      //     blogPosted: 'Jan 10, 2020',
      //     tags: ['Quote', 'Fashion'],
      //     excerpt: 'Muffin liquorice candy soufflé bear claw apple pie icing halvah. Pie marshmallow jelly.',
      //     comment: 319,
      //   },
      //   {
      //     id: 6,
      //     img: require('@/assets/images/slider/10.jpg'),
      //     title: 'Ships at a distance have Every Man’s Wish on Board',
      //     avatar: require('@/assets/images/portrait/small/avatar-s-13.jpg'),
      //     userFullName: 'Helena Hunt',
      //     blogPosted: 'Jan 10, 2020',
      //     tags: ['Fashion', 'Video'],
      //     excerpt: 'A little personality goes a long way, especially on a business blog. So don’t be afraid to let loose.',
      //     comment: 1500,
      //   },
      // ]
    }
  },
  created() {


    this.$http.get('/blog/list/data').then(res => { this.blogList = res.data })
    this.$http.get('/blog/list/data/sidebar').then(res => { this.blogSidebar = res.data })
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);

    this.ecommerce.getECommerceStats({}).then((stats) => {
      console.log('perf ', stats)
      this.lineChart.data.labels = stats.columns;
      this.lineChart.data.datasets[0].data = stats.values;
      this.flagLineChart = true;
    })


    this.ecommerce.getCategories({
      get_all: true,
      get_active: false,
      has_services: false,
      has_services_items: false,
      user_area: '',
      id_user: 0
    }).then((categoriesList) => {

      this.bufferCategoriesList = categoriesList;
      this.computedCategories = categoriesList;
      this.computedCurrentPage = 1;

      this.ecommerce.getPromocionals({
        get_all: false,
        get_active: true,
        has_services: false,
        has_services_items: false
      }).then((promocionalsList) => {

        this.computedPromocionals = promocionalsList;
        this.loading = false;


      });

      this.ecommerce.getPromocionals({
        get_all: false,
        get_active: false,
        has_services: false,
        has_services_items: false
      }).then((promocionalsList) => {

        this.computedPromocionalsInactive = promocionalsList;
        this.loading = false;


      });
      // this.computedQuerySearch = '';

      console.log('parapara ', categoriesList);

    });

  },
  computed: {

    computedPromocionals: {
      get: function () { return this.promocionals },
      set: function (editable) {
        this.promocionals = editable;
      }
    },

    computedPromocionalsInactive: {
      get: function () { return this.promocionalsInactive },
      set: function (editable) {
        this.promocionalsInactive = editable;
      }
    },

    computedEditableCategory: {
      get: function () { return this.editableCategory },
      set: function (editable) {
        this.editableCategory = editable;
      }
    },

    computedCurrentPage: {
      get: function () {
        if (this.currentPage > 1) {
          this.rows.pagesQty = 4 * (this.currentPage - 1);
          this.rows.pagesLength = (4 * (this.currentPage - 1)) + 4;
          // this.rewardsList = this.rewardsList.slice(4, 20);
        } else {
          this.rows.pagesQty = 0;
          this.rows.pagesLength = 4;
        }

        console.log(this.currentPage);
        return this.currentPage
      },
      set: function (current) {
        this.currentPage = current
      }
    },
    computedCategories: {
      get: function () { return this.rewardsList },
      set: function (categoriesList) {
        this.rewardsList = categoriesList
      }
    },
    computedQuerySearch: {
      get: function () { console.log('cambiando ', this.search_query); return this.search_query },
      set: function (querySearch) {
        this.search_query = querySearch;

        if (querySearch !== '') {

          this.ecommerce.filterCategories(this.bufferCategoriesList, querySearch).then((categoriesMatch) => {

            console.log('compos ', categoriesMatch);
            this.computedCategories = categoriesMatch;
            this.computedCurrentPage = 1;
          });
        } else {

          this.computedCategories = this.bufferCategoriesList;
          this.computedCurrentPage = 1;
        }

      }
    }
  },
  methods: {

    createPromocional(newpromocional) {
      this.ecommerce.createPromocional(newpromocional).then((newprom) => {
        console.log('ok ', newprom)
      })

    },

    updatePromocional(newpromocional) {
      this.ecommerce.updatePromocional(newpromocional).then((newprom) => {
        console.log('ok 202020', newprom)
      })

    },
    

    getNewSubCategoryImg(event) {

const file = event.target.files[0];
const reader = new FileReader();
reader.onloadend = () => {
  this.newSubCategoryImage = reader.result;
  this.newServiceItem.image = this.newSubCategoryImage + '';
//  this.computedEditableCategory.services[0].img = this.newSubCategoryImage + '';
  //console.log(newServiceItem.img);
  // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
};
reader.readAsDataURL(file);
},


    popCatImg(eventx) {
      this.$refs.refInputCatUpdate[0].click()
    },
    popServImg(eventx) {
      this.$refs.fileBtnUpdateServ.click()
    },

    popServItemImg(eventx) {
      this.$refs.fileBtn.click()
    },


    getNewCategoryImg(event) {

      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        this.newCategoryImage = reader.result;
        this.newService.img = this.newCategoryImage + '';
        console.log('edita 2', this.newService)
        // Logs data:<type>;base64,wL2dvYWwgbW9yZ...
      };
      reader.readAsDataURL(file);
    },

    updateCategoryImg(event) {

      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        this.editableCategoryImage = reader.result;
        this.computedEditableCategory.img = this.editableCategoryImage + '';
      };
      reader.readAsDataURL(file);
    },

    setPromocionalIndexSelected(index) {

      this.editablePromocional = this.computedPromocionals[index];
      this.flagEditablePromocional = true;


    },

    openPromocionalForm(index) {

      this.editablePromocional = new Promocional();
      this.flagNewPromocional = true;


    },

    createServiceItem(eventx) {

      this.loadingModal = true;
      this.newServiceItem.id_service = this.computedEditableCategory.services[this.indexSelectedCategoryService].id_service
      this.newServiceItem.attributes = [];
      this.newServiceItem.quantity = this.newServiceItem.quantity * 1;
      this.newServiceItem.image = this.newSubCategoryImage;
      this.ecommerce.createServiceItem(this.newServiceItem).then((serviceItemCreated) => {

        serviceItemCreated['attributes'] = [new Attribute(), new Attribute()];
        this.computedEditableCategory.services[this.indexSelectedCategoryService].catalog.push(serviceItemCreated);
        this.$toast({
          component: ToastContent,

          position: 'b-toaster-top-center',
          props: {
            title: `Se Ha Creado Con Exito el Item ${this.newServiceItem.name} a la Categoria ${this.computedEditableCategory.services[this.indexSelectedCategoryService].name}`,
            icon: 'CoffeeIcon',
            variant: 'default',
            text: `Bien Hecho !`,
            bgcolor: '#55a64b'

          },
          delay: 15000
        });

        // this.flagEditableCategory = false;
        this.loadingModal = false;
        // this.$bvModal.hide('modal-primary');

      });

    },

    createService(eventx) {

      this.newService.id_category = this.computedEditableCategory.id_category;
      this.newService.img = this.newCategoryImage;
      this.newService.excerpt = "0.1";
      this.newService.comment = 0;
      this.ecommerce.createService(this.newService).then((serviceCreated) => {

        this.computedEditableCategory.services.push(serviceCreated);

        this.ecommerce.getCategoryServices(this.computedEditableCategory.id_category).then((servicesList) => {


          this.computedEditableCategory.services = servicesList;
          this.flagEditableCategory = true;
          this.flagEditableCategoryServices = true;
          // document.getElementById('modal-primary').addEventListener("hidden.bs.modal", alert('relac'));
          this.$toast({
            component: ToastContent,

            position: 'b-toaster-top-center',
            props: {
              title: `Se Ha Creado Con Exito el Servicio ${this.newService.name} a la Categoria ${this.computedEditableCategory.name}`,
              icon: 'CoffeeIcon',
              variant: 'default',
              text: `Bien Hecho !`,
              bgcolor: '#55a64b'

            },
            delay: 15000
          });

          // this.flagEditableCategory = false;
          this.loadingModal = false;
          //   this.$bvModal.hide('modal-primary');


        });
        console.log('okok ', this.computedEditableCategory);

      });


      // console.log('creando ', this.newService);
    },

    paginate(eventx) {

      return new FilterObjects().paginateArrayObject(this.computedCategories, 4, 6).pagesQty;

      // this.ecommerce.filterCategories(this.computedCategories, this.computedQuerySearch).then((categoriesMatch) => {

      //   console.log('compos ', categoriesMatch);
      //   this.computedCategories = categoriesMatch;

      // });

    },

    startEditCategory(property, eventx) {
      this.computedEditableCategory['' + property] = '' + eventx.srcElement.innerHTML;

    },

    startEditService(property, eventx) {
      (this.computedEditableCategory.services[this.indexSelectedCategoryService])['' + property] = '' + eventx.srcElement.innerHTML;

    },

    createCategory(newCategory) {

      this.ecommerce.createCategory(newCategory).then((newCategoryResult) => {

        this.$bvModal.hide('modal-primary-form');
        this.$toast({
          component: ToastContent,

          position: 'b-toaster-top-center',
          props: {
            title: `Se Ha Creado Con Exito La Categoria`,
            icon: 'CoffeeIcon',
            variant: 'default',
            text: `Bien Hecho !`,
            bgcolor: '#55a64b'

          },
          delay: 15000
        });

        // this.flagEditableCategory = false;
        this.loadingModal = false;

        // this.ecommerce.getCategories({
        //   get_all: true,
        //   get_active: false,
        //   has_services: false,
        //   has_services_items: false,
        //   id_user: 0
        // }).then((categoriesList) => {

          this.bufferCategoriesList.push(newCategoryResult); // = categoriesList;
          this.computedCategories.push(newCategoryResult); // = categoriesList;
          this.computedCurrentPage = 1;
        // });



      });
    },

    updateService() {


      this.ecommerce.updateService(this.newService).then((serviceUpdated) => {
        this.$toast({
          component: ToastContent,

          position: 'b-toaster-top-center',
          props: {
            title: `Se Ha Actualizado con Exito el Servicio ${this.newService.name}`,
            icon: 'CoffeeIcon',
            variant: 'default',
            text: `Bien Hecho !`,
            bgcolor: '#55a64b'

          },
          delay: 15000
        });


      });
    },

    updateServiceItem() {

      this.ecommerce.updateServiceItem(this.newServiceItem).then((serviceItemUpdated) => {

        this.$toast({
          component: ToastContent,

          position: 'b-toaster-top-center',
          props: {
            title: `Se Ha Actualizado con Exito el Item de Servicio ${this.newServiceItem.name}`,
            icon: 'CoffeeIcon',
            variant: 'default',
            text: `Bien Hecho !`,
            bgcolor: '#55a64b'

          },
          delay: 15000
        });


      });
    },

    editCategoryHidden(eventx) {

      this.flagEnableCategoryEdit = false;
      this.flagEditableCategory = false;
      this.flagEditableCategoryServices = false;
      this.flagUpdateServices = false;
      this.flagUpdateServicesItem = false;

      //      delete this.computedEditableCategory.tags;
      this.computedEditableCategory.services = [];
      
      this.newService = {
        name: '',
        description: '',
        tags: '',
        img: '/img/04.png'
      }
      this.newCategoryImage = '';
      this.newSubCategoryImage = '';
      this.ecommerce.updateCategory(this.computedEditableCategory).then((categoryUpdated) => {

        this.$toast({
          component: ToastContent,

          position: 'b-toaster-top-center',
          props: {
            title: `Se Ha Actualizado con Exito La Categoria ${this.newServiceItem.name}`,
            icon: 'CoffeeIcon',
            variant: 'default',
            text: `Bien Hecho !`,
            bgcolor: '#55a64b'

          },
          delay: 15000
        });
      });

    },

    enableCategoryEdit(eventx) {

      this.flagEnableCategoryEdit = !this.flagEnableCategoryEdit;

    },

    enableServiceEdit(eventx) {

      this.flagEnableServiceEdit = !this.flagEnableServiceEdit;

    },

    hideCategoryService() {

      this.flagEditableCategoryServices = true;

    },

    enableCategoryServiceEdit(service) {
      console.log('pucha', service);
      // service.tags = {
      //   tags: ''
      // }
      service.is_active = 1;
      service.is_available = 1;
      this.newService = service;
      this.flagUpdateServices = !this.flagUpdateServices;
    },
    enableServiceItemEdit(serviceItem) {
      console.log('pucha Item', serviceItem);
      // service.tags = {
      //   tags: ''
      // }

      this.newServiceItem = serviceItem;
      this.flagUpdateServicesItem = !this.flagUpdateServicesItem;
    },
    showCategoryService(service, index) {

      this.indexSelectedCategoryService = index;
      console.log('milo ', service);
      this.ecommerce.getCategoryServicesItems(service.id_service).then((serviceItems) => {

        this.computedEditableCategory.services[this.indexSelectedCategoryService].catalog = serviceItems;
        this.flagEditableCategoryServices = false;

      });

    },
    setEditableCategory(editableCategory) {
      this.computedEditableCategory = editableCategory;
      this.computedEditableCategory.services = [];

      this.ecommerce.getCategoryServices(editableCategory.id_category).then((servicesList) => {


        this.computedEditableCategory.services = servicesList;
        this.flagEditableCategory = true;
        this.flagEditableCategoryServices = true;
        console.log('garbage ', document.getElementById('modal-primary'));

        // document.getElementById('modal-primary').addEventListener("hidden.bs.modal", alert('relac'));

      });

    },
    setEditablePromocional(editablePromocional) {

      this.promocional = editablePromocional;



    },
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-primary'
    },
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-blog.scss';
@import '@core/scss/vue/pages/page-pricing.scss';

@font-face {
  font-family: 'FredokaOne';
  src: url('/assets/fonts/FredokaOne-Regular.ttf');
}

@font-face {
  font-family: 'MavenPro';
  src: url('/assets/fonts/MavenPro-VariableFont_wght.ttf');
}

.startTrainingHeaderStyle {
  height: 120px !important;
  font-size: 20px !important;
  text-align: center !important;
  margin-top: 0px !important;
  width: 100% !important;
  font-family: 'MavenPro' !important;
}

.editCategoryButtonStyle {
  position: relative !important;
  float: left !important;
}

.serviceTableStyle {
  margin-left: 0px !important;
  border-collapse: collapse;
  font-family: 'MavenPro' !important;
  border-radius: 10px !important;
  width: 100% !important;
  margin-top: 0px !important;
  overflow-y: auto !important;
  height: 100% !important;
}


.serviceTableStyle th {
  border: #4e95f4 0px solid;
  font-size: 14px !important;
  color: #ffffff !important;
  background-color: #6d36ac !important;
  text-align: justify !important;
  font-family: 'MavenPro' !important;
  height: 20px !important;
}

.serviceTableStyle td {
  border: #4e95f4 0px solid;
  text-align: justify !important;
  font-family: 'MavenPro' !important;

}

/* provide some minimal visual accomodation for IE8 and below */
.serviceTableStyle tr {
  background: #b8d1f3;
}

/*  Define the background color for all the ODD background rows  */
.serviceTableStyle tr:nth-child(odd) {
  background: #f2edf7;
}

/*  Define the background color for all the EVEN background rows  */
.serviceTableStyle tr:nth-child(even) {
  background: #f2faf0;
}

.modal-dialog {

  width: 100% !important;

  height: 100% !important;

}
</style>
